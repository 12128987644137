import React from 'react'
import { SRLWrapper } from "simple-react-lightbox";
import { GatsbyImage } from 'gatsby-plugin-image'
import { graphql,Link } from 'gatsby'
import {Box,Heading,Text,SimpleGrid} from '@chakra-ui/react'
import { MyToast } from '../components/toast';
import MeinSeo from '../components/seo'



const bildname2=[
  {'nr':'01','name':'Carl Haag: Old Chapel 1887'},
  {'nr':'02','name':'Prozessionsfigur, Oberwesel 17. Jh'},
  {'nr':'03','name':'Wernerkapelle Oberwesel, Ansichtskarte 1941'},
  {'nr':'04','name':'Gedenkstein am Bacharacher Mahnmal Wernerkapelle'},
  {'nr':'05','name':'Wernerdarstellung am Weinlehrpfad in Oberwesel'},
  {'nr':'06','name':'Oberweseler Ritualmordrelief im »Stürmer« 1939'},
  {'nr':'07','name':'Ritualmordrelief, bis 1970 an der Wernerkapelle Oberwesel'},
  {'nr':'08','name':'»Introitus in festo Werneri« aus dem Bacharacher Graduale, 17. Jh.'},
  {'nr':'09','name':'Werner-Prozession 1928 vor der Synagoge in Oberwesel'},
  {'nr':'10','name':'Altarbild in der Wernerkapelle Womrath'},
  {'nr':'11','name':'Kelch mit Ritualmorddarstellung aus Womrath, 1753'},
  {'nr':'12','name':'Altarbild von Francis Jourdain, Besançon 1788'},
  {'nr':'13','name':'Claude-Antoine Beau: Porträt von Auguste Courbet als St. Vernier'},
  {'nr':'14','name':'Verny-Statuette in Beaumont, Frankreich'},
  {'nr':'15','name':'Weinflaschenetikett aus Bacharach'},
  {'nr':'16','name':'Andachtsbildchen, 19. Jh.'},
  {'nr':'17','name':'Hans Franke: Sankt Werner, Oberwesel 1923'},
  {'nr':'18','name':'Buntglasfenster von Heinrich Diekmann, Priesterseminar Trier 1930'},
  {'nr':'19','name':'Wernerfenster in der Pfarrkirche von Damscheid, 1959'},
  {'nr':'20','name':'Wernerfenster in der Wendelinuskapelle in Lieg'},
  {'nr':'21','name':'Pilgerzeichen, Bacharach 16. Jh.'},
  {'nr':'22','name':'Weinlage St. Wernerberg bei Dellhofen'},
  {'nr':'23','name':'Umschlag Werner-Büchleins des Kaplans Werner Geiger, 1938'},
  {'nr':'24','name':'Heiligenlegende 1844'},
  {'nr':'25','name':'Ritualmord-Sondernummer des Hetzblatts »Der Stürmer« 1939'},
  {'nr':'26','name':'Kalenderblatt im niederländischen heiligen.net 2021'},
  {'nr':'27','name':'Altarbild von Bernhard Scherer, Bacharach 1938'},
  {'nr':'28','name':'Werbung auf der Hauswand der Gölzschen Weinkellerei St. Werner, Bacharach'},
  {'nr':'29','name':'Altarbild von Peter Molitor, Oberwesel 1845'},
  {'nr':'30','name':'Gewölbe unter der Wernerkapelle Oberwesel, angeblicher Tatort'},
  {'nr':'31','name':'Wernerstatue am Rathauskeller von Oberwesel'},
  {'nr':'32','name':'Andachts-Büchlein für die Pfarrei Oberwesel mit Werner-Litanei, 1906'},
  {'nr':'33','name':'Truhe mit Werner-Darstellung, 1787'},
  {'nr':'34','name':'Ritualmordskulptur, bis 1966 auf Hochaltar der St. Martinskirche Oberwesel'},
  {'nr':'35','name':'Wandgrab in der Bacharacher Wernerkapelle, geöffnet 1621'},
  {'nr':'36','name':'Daniel Papebroch, Verfasser der Werner-Kapitels in den »Acta Sanctorum«, 1715'}

]




const options ={
  buttons: {
    showDownloadButton: false
  },
  thumbnails: {
    showThumbnails: false,
    thumbnailsAlignment: 'center',
    thumbnailsContainerBackgroundColor: 'black',
    thumbnailsContainerPadding: '0',
    thumbnailsGap: '0 1px',
    thumbnailsIconColor: '#000',
    thumbnailsOpacity: 1,
    thumbnailsPosition: 'bottom',
    thumbnailsSize: ['80px', '80px']
  }
}

  function WernerDokumente({data}) {
   
    return (
<Box pb="100px"  mt="10">
     <MeinSeo title="Dokumente vom Werner-Kult" description="Der Wernerkult.Erklärt von Dr. Walter Karbach" />

        <SRLWrapper options={options}>
         
        <Box mb="10">
        <Heading variant="Titel"  >Dokumente &amp; Foto-Galerie zum Wernerkult</Heading>
        <Link to="/beitrag-4"> &rarr; Informationen zum Wernerkult</Link>
        </Box>
       
          <Text  fontSize="sm"><MyToast/></Text> 
          <SimpleGrid gap={3} columns={[1,1,2,3]}> 
           
           
          
        {data.images.edges.map((e,index) => {

          return (
                        
    <Box width="320"  shadow="md"      cursor="pointer" key={index} >
          
          <GatsbyImage

           image={e.node.childImageSharp.gatsbyImageData}

           alt={bildname2[index]?.name}
          

/>
 <Box p="2" textAlign="center"   bottom="0" position="relative" mx="auto" zIndex="3" > 
<Text lineHeight="1.1" fontWeight="200" display="block" textAlign="center"    color="text"  p="1"   fontSize="xs"> {bildname2[index]?.name!==undefined?bildname2[index].name:""}</Text>
</Box>
</Box> 




)

})}
</SimpleGrid>
        </SRLWrapper>

     
      </Box>
    );

  }

  export const query = graphql`

  {

    images: allFile(
      filter: { relativeDirectory: { eq: "werner" } }
      sort: { order: ASC, fields: base }
    ) {
      edges {

        node {

          base

          id

          relativePath

          childImageSharp {
             gatsbyImageData(
              layout: CONSTRAINED
              placeholder: BLURRED
              transformOptions: {
                cropFocus: ATTENTION
                fit: CONTAIN
                
              }
              aspectRatio: 1
              width: 900
              
             
            )
          }

        }

      }

    }
    thumbnails: allFile(
        filter: { relativeDirectory: { eq: "werner/thumbnails" } }
        sort: { order: ASC, fields: size }
  
      ) {
  
        edges {
  
          node {
  
            name
  
            id
  
            relativePath
  
            childImageSharp {
  
              gatsbyImageData(
  
                placeholder: BLURRED
  
                layout: FIXED
  
                width: 60
  
                height: 60
  
              )
  
            }
  
          }
  
        }
  
      }
  
    

  }`


  export default WernerDokumente;




